
.home-slider-bg.is-0 {
    background-color: #8a8a8a;
}

.home_slider-cta {
    display: none;
}


.section_slider-home {
    position: relative;
    height: 100vh;
}

.home-slider-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home_slider-arrow_left,
.home_slider-arrow-right {
    position: absolute;
    z-index: 30;
    cursor: pointer;
    transition: all 400ms;
}

.home_slider-arrow_left.hide,
.home_slider-arrow-right.hide {
    opacity: 0;
    pointer-events: none;
    transition: all 400ms 400ms;
}

.home_slider-arrow_left {
    left: 0;
}

.home_slider-arrow_left:hover,
.home_slider-arrow_left:active {
    transform: translateX(-20px);
    transition: all 400ms;
}
 
.home_slider-arrow-right {
    right: 0;
}

.home_slider-arrow-right:hover,
.home_slider-arrow-right:active {
    transform: translateX(20px);
    transition: all 400ms;
}

.home-slider-bg {
    border-radius: 100%;
    width: 150%;
    height: 200%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
    left: 50%;
}