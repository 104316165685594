
body:not(.safari) [data-animation-floating],
body:not(.safari) .slider_icon .slider-icon_img {
	animation: float 2s ease-in-out infinite;
    will-change: transform;
}

.slider_icon .slider-icon_img {
	position: relative;
}

body:not(.safari) .icon-shadow {
	animation: shadow 2s ease-in-out infinite;
    will-change: opacity;
}

.slider_icon.is-1 .slider-icon_img,
.slider_icon.is-1 .icon-shadow {
    animation-duration: 2s;
}

.slider_icon.is-2 .slider-icon_img,
.slider_icon.is-2 .icon-shadow {
    animation-duration: 1.6s;
}

.slider_icon.is-3 .slider-icon_img,
.slider_icon.is-3 .icon-shadow {
    animation-duration: 1.8s;
}

[data-animation-floating="1000"] { animation-duration: 1.0s; }
[data-animation-floating="1100"] { animation-duration: 1.1s; }
[data-animation-floating="1200"] { animation-duration: 1.2s; }
[data-animation-floating="1300"] { animation-duration: 1.3s; }
[data-animation-floating="1400"] { animation-duration: 1.4s; }
[data-animation-floating="1500"] { animation-duration: 1.5s; }
[data-animation-floating="1600"] { animation-duration: 1.6s; }
[data-animation-floating="1700"] { animation-duration: 1.7s; }
[data-animation-floating="1800"] { animation-duration: 1.8s; }
[data-animation-floating="1900"] { animation-duration: 1.9s; }
[data-animation-floating="2100"] { animation-duration: 2.1s; }
[data-animation-floating="2200"] { animation-duration: 2.2s; }
[data-animation-floating="2300"] { animation-duration: 2.3s; }
[data-animation-floating="2400"] { animation-duration: 2.4s; }
[data-animation-floating="2500"] { animation-duration: 2.5s; }
[data-animation-floating="2600"] { animation-duration: 2.6s; }
[data-animation-floating="2700"] { animation-duration: 2.7s; }
[data-animation-floating="2800"] { animation-duration: 2.8s; }
[data-animation-floating="2900"] { animation-duration: 2.9s; }
[data-animation-floating="3000"] { animation-duration: 3.0s; }

@keyframes float {
	0% {
		transform: translateY(0px);
	}
	55% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes shadow {
	0% {
		opacity: 0.4;
	}
	55% {
		opacity: 1;
	}
	100% {
		opacity: 0.4;
	}
}