
.service-slider_sticky .section-title {
    z-index: 10;
}

.service-slider_wrapper {
    z-index: 2;
}

.checkpoint-cointainer {
    pointer-events: none;
}

.section_service-slider .checkpoint-cointainer .checkpoint {
  height: 150vh;
}

.service_floating-item,
.home-time-bg,
.home-service_title,
.home-service_title-daycare,
.home-service_cta-cont,
.service-slider_panel .bubble {
    transition: all 300ms ease;
}

.service-slider_panel.is-current {
    z-index: 5;
}

.service-slider_panel:not(.is-current) {
    pointer-events: none;
}

.service-slider_panel:not(.is-current) .service_floating-item,
.service-slider_panel:not(.is-current) .home-service_title,
.service-slider_panel:not(.is-current) .home-service_title-daycare,
.service-slider_panel:not(.is-current) .home-service_cta-cont,
.service-slider_panel:not(.is-current) .bubble {
    opacity: 0;
    transform: scale(0.4);
}

.service-slider_panel .is-2 .service_floating-item { transition-delay: 100ms; }
.service-slider_panel .is-3 .service_floating-item { transition-delay: 200ms; }
.service-slider_panel .is-4 .service_floating-item { transition-delay: 300ms; }
.service-slider_panel .is-5 .service_floating-item { transition-delay: 400ms; }
.service-slider_panel .is-6 .service_floating-item { transition-delay: 500ms; }

.service-slider_panel:not(.is-current) .home-time-bg {
    opacity: 0;
}

.service-slider_wrapper[data-current-slide="0"] .home-time-bg path {
    fill: #A3BFCB;
}

.service-slider_wrapper[data-current-slide="1"] .home-time-bg path {
    fill: #8A8A8A;
}