
.nav_menu {
    display: block;
    pointer-events: none;
}

.nav_bg {
    bottom: auto;
    top: 0px;
    left: 100%;
    right: auto;
    transform: translate(-50%, -50%);
    width: 300vh !important;
    height: 300vh !important;
    border-radius: 50%;
    will-change: transform;
}

@media only screen and (min-width: 480px){
    .nav_bg {
        top: 55px;
        left: 50%;
        margin-left: -55px;
    }
}

.nav_menu_hamb {
    height: 15px;
    width: 40px;
    position: relative;
}

.nav_menu_trigger {
    cursor: hand;
    cursor: pointer;
}

.nav_menu_hamb .nav_menu_line {
    position: absolute;
    left: 50%;
    width: 40px;
    margin-left: -20px;
    margin-top: 0;
    transition: all 200ms ease;
}

.nav_menu_hamb .nav_menu_line._1 {
    top: 0;
}

.nav_menu_hamb .nav_menu_line._2 {
    top: 100%;
}

.nav_menu_trigger:hover .nav_menu_line._1,
.nav_menu_trigger:hover .nav_menu_line._1 {
    top: -20%;
}

.nav_menu_trigger:hover .nav_menu_line._2,
.nav_menu_trigger:hover .nav_menu_line._2 {
    top: 120%;
}

body.menuIsOpen {
    overflow: hidden;
}

body.menuIsOpen .nav_menu {
    opacity: 1;
    pointer-events: all; 
}

body.menuIsOpen .nav_container .nav {
    position: fixed;
}

body.menuIsOpen .nav_menu_hamb .nav_menu_line._1 {
    top: 50%;
    transform: rotate(-45deg);
}

body.menuIsOpen .nav_menu_hamb .nav_menu_line._2 {
    top: 50%;
    transform: rotate(45deg);

}