
@import './css/reset.css';
@import './css/menu.css';
@import './css/service-slider.css';
@import './css/home-slider.css';
@import './css/home-service-slider.css';
@import './css/floating.css';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  
.button-border {
  pointer-events: none;
}

[data-animation-rotate] {
  animation: rotateEl 8s infinite linear;
}

.home-service_item-wrapper:hover .home-service_item-border circle {
  stroke-dasharray: 20 0;
}

@keyframes rotateEl {
  from { transform:rotate(0) }
  to { transform:rotate(360deg) }
}


.bubble {
	opacity: 0.8;
  pointer-events: none;
}