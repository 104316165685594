body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

[tabindex]:focus-visible, input[type="file"]:focus-visible {
  outline-offset: .125rem;
  outline: .125rem solid #4d65ff;
}

.w-richtext > :not(div):first-child, .w-richtext > div:first-child > :first-child {
  margin-top: 0 !important;
}

.w-richtext > :last-child, .w-richtext ol li:last-child, .w-richtext ul li:last-child {
  margin-bottom: 0 !important;
}

.pointer-events-off {
  pointer-events: none;
}

.pointer-events-on {
  pointer-events: auto;
}

.div-square:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.container-medium, .container-small, .container-large {
  margin-left: auto !important;
  margin-right: auto !important;
}

.text-style-3lines {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.text-style-2lines {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.display-inlineflex {
  display: inline-flex;
}

.hide {
  display: none !important;
}

@media screen and (width <= 991px) {
  .hide, .hide-tablet {
    display: none !important;
  }
}

@media screen and (width <= 767px) {
  .hide-mobile-landscape {
    display: none !important;
  }
}

@media screen and (width <= 479px) {
  .hide-mobile {
    display: none !important;
  }
}

.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.spacing-clean {
  margin: 0 !important;
  padding: 0 !important;
}

.margin-top {
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.padding-top {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.margin-right {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}

.padding-right {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.margin-bottom {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.padding-bottom {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.margin-left {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

.padding-left {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

.margin-horizontal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.padding-horizontal {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.margin-vertical {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.padding-vertical {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.nav_menu {
  pointer-events: none;
  display: block;
}

.nav_bg {
  will-change: transform;
  border-radius: 50%;
  inset: 0 auto auto 100%;
  transform: translate(-50%, -50%);
  width: 300vh !important;
  height: 300vh !important;
}

@media only screen and (width >= 480px) {
  .nav_bg {
    margin-left: -55px;
    top: 55px;
    left: 50%;
  }
}

.nav_menu_hamb {
  width: 40px;
  height: 15px;
  position: relative;
}

.nav_menu_trigger {
  cursor: hand;
  cursor: pointer;
}

.nav_menu_hamb .nav_menu_line {
  width: 40px;
  margin-top: 0;
  margin-left: -20px;
  transition: all .2s;
  position: absolute;
  left: 50%;
}

.nav_menu_hamb .nav_menu_line._1 {
  top: 0;
}

.nav_menu_hamb .nav_menu_line._2 {
  top: 100%;
}

.nav_menu_trigger:hover .nav_menu_line._1, .nav_menu_trigger:hover .nav_menu_line._1 {
  top: -20%;
}

.nav_menu_trigger:hover .nav_menu_line._2, .nav_menu_trigger:hover .nav_menu_line._2 {
  top: 120%;
}

body.menuIsOpen {
  overflow: hidden;
}

body.menuIsOpen .nav_menu {
  opacity: 1;
  pointer-events: all;
}

body.menuIsOpen .nav_container .nav {
  position: fixed;
}

body.menuIsOpen .nav_menu_hamb .nav_menu_line._1 {
  top: 50%;
  transform: rotate(-45deg);
}

body.menuIsOpen .nav_menu_hamb .nav_menu_line._2 {
  top: 50%;
  transform: rotate(45deg);
}

.service-slider_circle-wrapper {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.checkpoint {
  height: 200vh;
}

.section_service-slider {
  min-height: 100vh;
}

.service-slider_wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.service-slider_sticky {
  height: 100vh;
  position: sticky;
  top: 0;
}

.service-slider_panel {
  z-index: 2;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.fix-it .service-slider_panel-inner {
  position: fixed;
}

.service-slider_title, .service-slider_text, .service-slider_item, .service-slider_desc-bottom, .service-slider__lottie, .service-slider-item_price {
  transition: all .3s;
}

.service-slider_title {
  transition-delay: 0s;
}

.service-slider_text {
  transition-delay: .1s;
}

.service-slider_desc-bottom {
  transition-delay: .2s;
}

.service-slider__lottie {
  transition-delay: .3s;
}

.service-slider-item_price {
  transition-delay: .4s;
}

.service-slider_panel:not(.is-current) .service-slider_title, .service-slider_panel:not(.is-current) .service-slider_text, .service-slider_panel:not(.is-current) .service-slider_desc-bottom {
  opacity: 0;
  transition-delay: 0s;
  transform: translateY(50px);
}

.service-slider_panel:not(.is-current) .service-slider__lottie {
  opacity: 0;
  transform: scale(.4);
}

.service-slider_panel:not(.is-current) .service-slider-item_price {
  opacity: 0;
  transition-delay: 0s;
  transform: translate(-50%, 50px);
}

.service-slider-nav {
  z-index: 10;
  background-color: #0000;
  width: 300px;
  height: auto;
  position: absolute;
  top: 20%;
  bottom: 20%;
  right: 0;
  overflow: hidden;
}

.service-slider-nav_path {
  width: auto;
  height: 150%;
  transition: all .4s;
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translateY(-50%)rotate(0);
}

.service-slider_wrapper[data-current-slide="1"] .service-slider-nav_path {
  transform: translateY(-50%)rotate(10deg);
}

.service-slider_wrapper[data-current-slide="2"] .service-slider-nav_path {
  transform: translateY(-50%)rotate(20deg);
}

.service-slider_wrapper[data-current-slide="3"] .service-slider-nav_path {
  transform: translateY(-50%)rotate(30deg);
}

.service-slider_wrapper[data-current-slide="4"] .service-slider-nav_path {
  transform: translateY(-50%)rotate(40deg);
}

.service-slider_wrapper[data-current-slide="5"] .service-slider-nav_path {
  transform: translateY(-50%)rotate(50deg);
}

.service-slider_wrapper[data-current-slide="6"] .service-slider-nav_path {
  transform: translateY(-50%)rotate(60deg);
}

.service-slider_wrapper[data-current-slide="7"] .service-slider-nav_path {
  transform: translateY(-50%)rotate(70deg);
}

.service-slider-nav_inner {
  z-index: 2;
  width: 100px;
  position: absolute;
  top: 20px;
  bottom: 20px;
  right: 0;
}

.slider-nav_img svg {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.service-slider_panel .nav-img--default, .service-slider_panel .nav-img--current {
  transition: all .2s;
  display: none;
}

.slider-nav_img {
  position: relative;
}

.slider-nav_img .nav-img--default {
  z-index: 2;
  position: relative;
}

.slider-nav_img .nav-img--current {
  opacity: 0;
  z-index: 2;
  position: absolute;
  left: 0;
}

.service-slider-nav_inner .slider-nav_item {
  color: #313131;
  transform-origin: center;
  border: 1px solid #01010100;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  font-size: 20px;
  transition: all .4s;
  position: absolute;
  left: 0;
  transform: scale(.8)translate(-50%, -50%);
}

.service-slider-nav_inner .slider-nav_item:after {
  content: "";
  opacity: 0;
  background-color: #d7d7d7;
  border-radius: 50%;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 8px;
  transform: scale(.4);
}

.background-gradient-blue .service-slider-nav_inner .slider-nav_item:after {
  background-color: #c7d8df;
}

.service-slider-nav_inner .slider-nav_label {
  opacity: 0;
  text-align: right;
  width: 150px;
  transition: all .4s;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-100%, -50%);
}

.service-slider-nav_inner .slider-nav_item.is-current .slider-nav_img .nav-img--current {
  opacity: 1;
}

.service-slider-nav_inner .slider-nav_item.is-current .slider-nav_img .nav-img--default {
  opacity: 0;
}

.service-slider-nav_inner .slider-nav_item.is-current .slider-nav_label, .service-slider-nav_inner .slider-nav_item:hover .slider-nav_label, .service-slider-nav_inner .slider-nav_item:active .slider-nav_label {
  opacity: 1;
  transition: all .4s;
  left: -10px;
}

.service-slider-nav_inner .slider-nav_item:hover, .service-slider-nav_inner .slider-nav_item:active {
  transform: scale(.9)translate(-50%, -50%);
}

.service-slider-nav_inner .slider-nav_item.is-current {
  border: 1px solid #01010133;
}

.service-slider-nav_inner .slider-nav_item.is-current .slider-nav_img svg {
  filter: grayscale(0%);
}

.service-slider-nav_inner .slider-nav_item.is-current:after {
  opacity: 1;
  transform: scale(1);
}

.service-slider-nav_inner .slider-nav_item[data-im="-3"] {
  top: 2%;
  left: 60%;
}

.service-slider-nav_inner .slider-nav_item[data-im="-2"] {
  top: 15%;
  left: 25%;
}

.service-slider-nav_inner .slider-nav_item[data-im="-1"] {
  top: 28%;
  left: -5%;
}

.service-slider-nav_inner .slider-nav_item[data-im="0"] {
  top: 50%;
  left: 0;
  transform: scale(1)translate(-50%, -50%);
}

.service-slider-nav_inner .slider-nav_item[data-im="1"] {
  top: 65%;
  left: -5%;
}

.service-slider-nav_inner .slider-nav_item[data-im="2"] {
  top: 78%;
  left: 25%;
}

.service-slider-nav_inner .slider-nav_item[data-im="3"] {
  top: 92%;
  left: 60%;
}

.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="-2"] {
  top: 10%;
  left: 50%;
}

.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="-1"] {
  top: 28%;
  left: 0%;
}

.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="1"] {
  top: 72%;
  left: 0%;
}

.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="2"] {
  top: 90%;
  left: 60%;
}

.service-slider-nav_inner .slider-nav_item.is-last {
  left: 120% !important;
}

.service-slider-nav_inner .slider-nav_item[data-im="-3"].is-last {
  top: -20%;
}

.service-slider-nav_inner .slider-nav_item[data-im="3"].is-last {
  top: 120%;
}

.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="-2"].is-last {
  top: -20%;
}

.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="2"].is-last, .service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="1"].is-last {
  top: 120%;
}

.home-slider-bg.is-0 {
  background-color: #8a8a8a;
}

.home_slider-cta {
  display: none;
}

.section_slider-home {
  height: 100vh;
  position: relative;
}

.home-slider-panel {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home_slider-arrow_left, .home_slider-arrow-right {
  z-index: 30;
  cursor: pointer;
  transition: all .4s;
  position: absolute;
}

.home_slider-arrow_left.hide, .home_slider-arrow-right.hide {
  opacity: 0;
  pointer-events: none;
  transition: all .4s .4s;
}

.home_slider-arrow_left {
  left: 0;
}

.home_slider-arrow_left:hover, .home_slider-arrow_left:active {
  transition: all .4s;
  transform: translateX(-20px);
}

.home_slider-arrow-right {
  right: 0;
}

.home_slider-arrow-right:hover, .home_slider-arrow-right:active {
  transition: all .4s;
  transform: translateX(20px);
}

.home-slider-bg {
  border-radius: 100%;
  width: 150%;
  height: 200%;
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
}

.service-slider_sticky .section-title {
  z-index: 10;
}

.service-slider_wrapper {
  z-index: 2;
}

.checkpoint-cointainer {
  pointer-events: none;
}

.section_service-slider .checkpoint-cointainer .checkpoint {
  height: 150vh;
}

.service_floating-item, .home-time-bg, .home-service_title, .home-service_title-daycare, .home-service_cta-cont, .service-slider_panel .bubble {
  transition: all .3s;
}

.service-slider_panel.is-current {
  z-index: 5;
}

.service-slider_panel:not(.is-current) {
  pointer-events: none;
}

.service-slider_panel:not(.is-current) .service_floating-item, .service-slider_panel:not(.is-current) .home-service_title, .service-slider_panel:not(.is-current) .home-service_title-daycare, .service-slider_panel:not(.is-current) .home-service_cta-cont, .service-slider_panel:not(.is-current) .bubble {
  opacity: 0;
  transform: scale(.4);
}

.service-slider_panel .is-2 .service_floating-item {
  transition-delay: .1s;
}

.service-slider_panel .is-3 .service_floating-item {
  transition-delay: .2s;
}

.service-slider_panel .is-4 .service_floating-item {
  transition-delay: .3s;
}

.service-slider_panel .is-5 .service_floating-item {
  transition-delay: .4s;
}

.service-slider_panel .is-6 .service_floating-item {
  transition-delay: .5s;
}

.service-slider_panel:not(.is-current) .home-time-bg {
  opacity: 0;
}

.service-slider_wrapper[data-current-slide="0"] .home-time-bg path {
  fill: #a3bfcb;
}

.service-slider_wrapper[data-current-slide="1"] .home-time-bg path {
  fill: #8a8a8a;
}

body:not(.safari) [data-animation-floating], body:not(.safari) .slider_icon .slider-icon_img {
  will-change: transform;
  animation: 2s ease-in-out infinite float;
}

.slider_icon .slider-icon_img {
  position: relative;
}

body:not(.safari) .icon-shadow {
  will-change: opacity;
  animation: 2s ease-in-out infinite shadow;
}

.slider_icon.is-1 .slider-icon_img, .slider_icon.is-1 .icon-shadow {
  animation-duration: 2s;
}

.slider_icon.is-2 .slider-icon_img, .slider_icon.is-2 .icon-shadow {
  animation-duration: 1.6s;
}

.slider_icon.is-3 .slider-icon_img, .slider_icon.is-3 .icon-shadow {
  animation-duration: 1.8s;
}

[data-animation-floating="1000"] {
  animation-duration: 1s;
}

[data-animation-floating="1100"] {
  animation-duration: 1.1s;
}

[data-animation-floating="1200"] {
  animation-duration: 1.2s;
}

[data-animation-floating="1300"] {
  animation-duration: 1.3s;
}

[data-animation-floating="1400"] {
  animation-duration: 1.4s;
}

[data-animation-floating="1500"] {
  animation-duration: 1.5s;
}

[data-animation-floating="1600"] {
  animation-duration: 1.6s;
}

[data-animation-floating="1700"] {
  animation-duration: 1.7s;
}

[data-animation-floating="1800"] {
  animation-duration: 1.8s;
}

[data-animation-floating="1900"] {
  animation-duration: 1.9s;
}

[data-animation-floating="2100"] {
  animation-duration: 2.1s;
}

[data-animation-floating="2200"] {
  animation-duration: 2.2s;
}

[data-animation-floating="2300"] {
  animation-duration: 2.3s;
}

[data-animation-floating="2400"] {
  animation-duration: 2.4s;
}

[data-animation-floating="2500"] {
  animation-duration: 2.5s;
}

[data-animation-floating="2600"] {
  animation-duration: 2.6s;
}

[data-animation-floating="2700"] {
  animation-duration: 2.7s;
}

[data-animation-floating="2800"] {
  animation-duration: 2.8s;
}

[data-animation-floating="2900"] {
  animation-duration: 2.9s;
}

[data-animation-floating="3000"] {
  animation-duration: 3s;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  55% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes shadow {
  0% {
    opacity: .4;
  }

  55% {
    opacity: 1;
  }

  100% {
    opacity: .4;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button-border {
  pointer-events: none;
}

[data-animation-rotate] {
  animation: 8s linear infinite rotateEl;
}

.home-service_item-wrapper:hover .home-service_item-border circle {
  stroke-dasharray: 20 0;
}

@keyframes rotateEl {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.bubble {
  opacity: .8;
  pointer-events: none;
}
/*# sourceMappingURL=main.css.map */
