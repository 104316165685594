
.service-slider_circle-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.checkpoint {
  height: 200vh;
}

.section_service-slider {
  min-height: 100vh;
}

.service-slider_wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.service-slider_sticky {
  position: sticky;
  top: 0;
  height: 100vh;
}

.service-slider_panel {
  /*position: fixed;*/
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  /*height: 200vh;*/
}

.fix-it .service-slider_panel-inner {
  position: fixed;
}

.service-slider_title,
.service-slider_text,
.service-slider_item,
.service-slider_desc-bottom,
.service-slider__lottie,
.service-slider-item_price {
  transition: all 300ms;
}

.service-slider_title {         transition-delay: 0ms; }
.service-slider_text {          transition-delay: 100ms; }
.service-slider_desc-bottom {   transition-delay: 200ms; }
.service-slider__lottie {       transition-delay: 300ms; }
.service-slider-item_price {    transition-delay: 400ms; }

.service-slider_panel:not(.is-current) {
  pointer-events: none;
}

.service-slider_panel:not(.is-current) .service-slider_title,
.service-slider_panel:not(.is-current) .service-slider_text,
.service-slider_panel:not(.is-current) .service-slider_desc-bottom {
  opacity: 0;
  transform: translateY(50px);
  transition-delay: 0ms;
}

.service-slider_panel:not(.is-current) .service-slider__lottie {
  opacity: 0;
  transform: scale(.4);
}

.service-slider_panel:not(.is-current) .service-slider-item_price {
  opacity: 0;
  transform: translate(-50%, 50px);
  transition-delay: 0ms;
}


.service-slider-nav {
  position: absolute;
  right: 0;
  top: 20%;
  bottom: 20%;
  height: auto;
  width: 300px;
  background-color: transparent;
  z-index: 10;
  overflow: hidden;
}

.service-slider-nav_path {
  position: absolute;
  top: 50%;
  left: 65%;
  height: 150%;
  width: auto;
  transform: translateY(-50%) rotate(0);
  transition: all 400ms;
}

.service-slider_wrapper[data-current-slide="1"] .service-slider-nav_path { transform: translateY(-50%) rotate(10deg); }
.service-slider_wrapper[data-current-slide="2"] .service-slider-nav_path { transform: translateY(-50%) rotate(20deg); }
.service-slider_wrapper[data-current-slide="3"] .service-slider-nav_path { transform: translateY(-50%) rotate(30deg); }
.service-slider_wrapper[data-current-slide="4"] .service-slider-nav_path { transform: translateY(-50%) rotate(40deg); }
.service-slider_wrapper[data-current-slide="5"] .service-slider-nav_path { transform: translateY(-50%) rotate(50deg); }
.service-slider_wrapper[data-current-slide="6"] .service-slider-nav_path { transform: translateY(-50%) rotate(60deg); }
.service-slider_wrapper[data-current-slide="7"] .service-slider-nav_path { transform: translateY(-50%) rotate(70deg); }

.service-slider-nav_inner {
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 20px;
  width: 100px;
  z-index: 2;
}

.slider-nav_img svg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  /*filter: grayscale(100%);*/
  /*mix-blend-mode: multiply;*/
  transform: translate(-50%, -50%) !important;
}

.service-slider_panel .nav-img--default,
.service-slider_panel .nav-img--current {
  display: none;
  transition: all 200ms;
}

.slider-nav_img {
  position: relative;
}


.slider-nav_img .nav-img--default {
  position: relative;
  z-index: 2;
}

.slider-nav_img .nav-img--current {
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: 2;
}

.service-slider-nav_inner .slider-nav_item {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  left: 0;
  transition: all 400ms;
  position: absolute;
  transform: scale(0.8) translate(-50%, -50%);
  color: #313131;
  font-size: 20px;
  border: 1px solid rgba(1, 1, 1, 0);
  transform-origin: center;
}

.service-slider-nav_inner .slider-nav_item::after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  background-color: #D7D7D7;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0.4);
  transition: all 300ms;
}


.background-gradient-blue .service-slider-nav_inner .slider-nav_item::after {
  background-color: #C7D8DF;
}

.service-slider-nav_inner .slider-nav_label {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-100%, -50%);
  opacity: 0;
  width: 150px;
  text-align: right;
  transition: all 400ms;
}

.service-slider-nav_inner .slider-nav_item.is-current .slider-nav_img .nav-img--current {
  opacity: 1;
}

.service-slider-nav_inner .slider-nav_item.is-current .slider-nav_img .nav-img--default {
  opacity: 0;
}

.service-slider-nav_inner .slider-nav_item.is-current .slider-nav_label,
.service-slider-nav_inner .slider-nav_item:hover .slider-nav_label,
.service-slider-nav_inner .slider-nav_item:active .slider-nav_label {
  opacity: 1;
  left: -10px;
  transition: all 400ms;
}

.service-slider-nav_inner .slider-nav_item:hover,
.service-slider-nav_inner .slider-nav_item:active {
  transform: scale(0.9) translate(-50%, -50%);
}

.service-slider-nav_inner .slider-nav_item.is-current {
  border: 1px solid rgba(1, 1, 1, 0.20);
}

.service-slider-nav_inner .slider-nav_item.is-current .slider-nav_img svg {
  filter: grayscale(0%);
}

.service-slider-nav_inner .slider-nav_item.is-current::after {
  transform: scale(1);
  opacity: 1;
}

.service-slider-nav_inner .slider-nav_item[data-im="-3"] { top: 2%; left: 60%; }
.service-slider-nav_inner .slider-nav_item[data-im="-2"] { top: 15%; left: 25%; }
.service-slider-nav_inner .slider-nav_item[data-im="-1"] { top: 28%; left: -5%; }

.service-slider-nav_inner .slider-nav_item[data-im="0"] { top: 50%; left: 0; transform: scale(1) translate(-50%, -50%); }

.service-slider-nav_inner .slider-nav_item[data-im="1"] { top: 65%; left: -5%; }
.service-slider-nav_inner .slider-nav_item[data-im="2"] { top: 78%; left: 25%; }
.service-slider-nav_inner .slider-nav_item[data-im="3"] { top: 92%; left: 60%; }


.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="-2"] { top: 10%; left: 50%; }
.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="-1"] { top: 28%; left: 0%; }
.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="1"] { top: 72%; left: 0%; }
.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="2"] { top: 90%; left: 60%; }

.service-slider-nav_inner .slider-nav_item.is-last {
  left: 120% !important;
}

.service-slider-nav_inner .slider-nav_item[data-im="-3"].is-last { top: -20%; }
.service-slider-nav_inner .slider-nav_item[data-im="3"].is-last { top: 120%; }


.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="-2"].is-last { top: -20%; }
.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="2"].is-last,
.service-slider-nav.items-count-4 .service-slider-nav_inner .slider-nav_item[data-im="1"].is-last { top: 120%; }
